jQuery(document).ready(function($) {
    $('a[href*="#"]:not([href="#"])').on('click', function(e) {
        e.stopPropagation();
        var target = $(this.hash);
        $('html,body').stop().animate({
            scrollTop: target.offset().top - 300
        }, 'linear');

    });


    if (location.hash){
        var id = $(location.hash);
    }

    $(window).load(function(e) {
        if (location.hash){
            $('html,body').animate({scrollTop: id.offset().top - 300}, 1000);
        };
    });
});

